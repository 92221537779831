import firebase from "firebase";
import { CategoriesType, FormResponseType } from "../services/firebase";
import { format } from "date-fns";
import { Clipboard } from "phosphor-react";
import { MonthType } from "../App";
import { TableCategorySection } from "../components";
import { FormResponseTotals } from "../utils/formResponseUtils";
import { Header, DateRange, LeftoverText } from "./styles";
import { NavigationFAB } from "../components/navigationFAB";
import styled from "styled-components";

const StyledClipboard = styled(Clipboard)`
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

type ListProps = {
  user: firebase.User;
  categories?: CategoriesType;
  selectedMonth: MonthType;
  formResponses?: FormResponseType;
  incomeTotals: FormResponseTotals;
  savingsTotals: FormResponseTotals;
  previousLeftovers: number;
  currentLeftovers: number;
  fixedExpensesTotals: FormResponseTotals;
  variableExpensesTotals: FormResponseTotals;
  getPreviousMonth: () => void;
  getNextMonth: () => void;
  refetchFormResponses: () => void;
  copyLastMonthsExpenses: () => void;
};

export const List: React.FC<ListProps> = ({
  user,
  categories,
  selectedMonth,
  formResponses = {
    incomeItems: [],
    savingsItems: [],
    fixedExpensesItems: [],
    variableExpensesItems: [],
    leftover: [],
  },
  incomeTotals,
  savingsTotals,
  fixedExpensesTotals,
  variableExpensesTotals,
  previousLeftovers,
  currentLeftovers,
  getPreviousMonth,
  getNextMonth,
  refetchFormResponses,
  copyLastMonthsExpenses,
}) => {
  return (
    <>
      <Header>
        hi, <strong>{user.displayName}</strong>
      </Header>
      <DateRange>
        {format(selectedMonth.start, "dd/MM/yyyy")} -{" "}
        {format(selectedMonth.end, "dd/MM/yyyy")}
      </DateRange>
      <LeftoverText>
        Rollover = <strong>{previousLeftovers.toFixed(2)}</strong>
      </LeftoverText>
      <LeftoverText>
        Current leftover = <strong>{currentLeftovers.toFixed(2)}</strong>
      </LeftoverText>
      {categories && (
        <>
          <TableCategorySection
            formResponseItems={formResponses.incomeItems}
            color="#CDF2CA"
            selectedMonth={selectedMonth}
            categoryLabel="Income"
            categoryValue={incomeTotals.value}
            categories={categories.income}
            uid={user.uid}
          />
          <TableCategorySection
            formResponseItems={formResponses.savingsItems}
            color="#FFDEFA"
            selectedMonth={selectedMonth}
            categoryLabel="Savings"
            categoryValue={savingsTotals.value}
            categories={categories.savings}
            uid={user.uid}
          />
          <TableCategorySection
            formResponseItems={formResponses.fixedExpensesItems}
            color="#ffd9a0"
            selectedMonth={selectedMonth}
            categoryLabel="Fixed Expenses"
            categoryValue={fixedExpensesTotals.value}
            categories={categories.fixedExpenses}
            uid={user.uid}
            actionSection={
              <StyledClipboard
                size={21}
                weight="bold" 
                onClick={(e) => {
                  e.stopPropagation();
                  copyLastMonthsExpenses();
                }}
              />
            }
          />
          <TableCategorySection
            formResponseItems={formResponses.variableExpensesItems}
            color="#DBC6FF"
            selectedMonth={selectedMonth}
            categoryLabel="Variable Expenses"
            categoryValue={variableExpensesTotals.value}
            categories={categories.variableExpenses}
            uid={user.uid}
          />
          <NavigationFAB
            uid={user.uid}
            categories={categories}
            onSuccess={refetchFormResponses}
            onLeftClick={getPreviousMonth}
            onRightClick={getNextMonth}
          />
        </>
      )}
    </>
  );
};
