import styled from "styled-components";

export const Header = styled.h1`
    font-weight: 400;
    font-size: 48px;
    padding-left: 8px;
    margin-bottom: 0;
`;

export const ButtonRow = styled.div`
    display: flex;
    & > *:not(:first-child) {
        margin-left: 18px;
    }
`;

export const DateRange = styled.h2`
    /* font-weight: 400; */
    padding-left: 8px;
    font-size: 24px;
`;

export const LeftoverText = styled.p`
    /* font-weight: 400; */
    padding-left: 8px;
    font-size: 16px;
`;