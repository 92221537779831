import { format, parseISO } from "date-fns";
import styled from "styled-components";
import { FormResponseItem } from "../../services/firebase";
import { CellInput, CellSelect } from "../cell";
import { SlideFadeTransition } from "../slideFadeTransition";

const StyledTable = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1px;
    background-color: #d0d0d0;
`;

export const Table: React.FC<FormResponseItem & { uid: string; show: boolean; categories: string[] }> = ({
    show,
    uid,
    timestamp,
    rowId,
    item,
    category,
    amount,
    categories,
}) => {
    return (
        <SlideFadeTransition show={show}>
            <StyledTable>
                <CellInput
                    columnName={"timestamp"}
                    rowId={rowId}
                    uid={uid}
                    first
                    value={timestamp}
                    formatValue={(value: string | number) => format(parseISO(value.toString()), "dd MMMM yy")}
                />
                <CellInput columnName={"item"} rowId={rowId} uid={uid} value={item} />
                <CellSelect
                    columnName={"category"}
                    rowId={rowId}
                    uid={uid}
                    value={category}
                    values={categories}
                    textAlign="right"
                />
                <CellInput columnName={"amount"} rowId={rowId} uid={uid} value={amount} textAlign="right" />
            </StyledTable>
        </SlideFadeTransition>
    );
};
