import { useEffect, useRef } from "react";
import { animated, useSpring } from "react-spring";
import useMeasure from "react-use-measure";
import styled from "styled-components";

const AnimatedDiv = styled(animated.div)`
    overflow: hidden;
`;

const usePrevious = <T,>(value: T) => {
    const ref = useRef<T>();
    useEffect(() => void (ref.current = value), [value]);
    return ref.current;
};

export const SlideFadeTransition: React.FC<{ show: boolean }> = ({ show, children }) => {
    const [ref, { height: viewHeight }] = useMeasure();
    const previous = usePrevious(show);

    const { maxHeight, opacity } = useSpring({
        from: { maxHeight: 0, opacity: 0, y: 0 },
        to: {
            maxHeight: show ? viewHeight : 0,
            opacity: show ? 1 : 0,
        },
    });

    return (
        <AnimatedDiv
            style={{
                opacity,
                maxHeight: show && previous === show ? "min-content" : maxHeight,
            }}
        >
            <div ref={ref}>{children}</div>
        </AnimatedDiv>
    );
};
