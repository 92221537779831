import firebase from "firebase";
import { reduceFormResponses } from "../utils/formResponseUtils";

export type FormResponseItem = {
  rowId: string;
  amount: number;
  category: string;
  item: string;
  timestamp: string;
};

export type FormResponseType = {
  incomeItems: FormResponseItem[];
  savingsItems: FormResponseItem[];
  fixedExpensesItems: FormResponseItem[];
  variableExpensesItems: FormResponseItem[];
  leftover: FormResponseItem[];
};

export const getFormResponses = async (
  uid: string,
  categories: CategoriesType
): Promise<FormResponseType> => {
  const db = firebase.database();
  const formResponses = await db.ref(`/formResponses/${uid}`).once("value");
  return reduceFormResponses(formResponses.val(), categories);
};

export type CategoriesType = {
  fixedExpenses: string[];
  variableExpenses: string[];
  income: string[];
  savings: string[];
};

export type UserDetails = {
  categories: CategoriesType;
  monthStartDate: number;
};

export const getUserDetails = async (uid: string): Promise<UserDetails> => {
  const db = firebase.database();
  const results = await db.ref(`/users/${uid}/`).once("value");
  const userDetails = results.val();
  if (!userDetails.categories)
    throw new Error("Categories not found, please try again in a minute.");
  return userDetails;
};

export type UpdateCellType = {
  uid: string;
  rowId: string;
  columnName: string;
  value: string | number;
};
export const updateCell = async ({
  uid,
  rowId,
  columnName,
  value,
}: UpdateCellType) => {
  const db = firebase.database();
  const result = await db
    .ref(`/formResponses/${uid}/${rowId}/${columnName}`)
    .set(value);
  return result;
};

export type Transaction = {
  category: string;
  item: string;
  amount: string;
  timestamp: string;
};
export type AddTransactionType = {
  uid: string;
  transaction: Transaction;
};
export const addTransaction = async ({
  uid,
  transaction,
}: AddTransactionType) => {
  const db = firebase.database();
  const result = await db.ref(`/formResponses/${uid}`).push(transaction);
  return result;
};

export type AddTransactionsType = {
  uid: string;
  transactions: Transaction[];
};
export const addTransactions = async ({
  uid,
  transactions,
}: AddTransactionsType) => {
  const db = firebase.database();
  let values: any = {};
  transactions.forEach((transaction) => {
    const key = db.ref(`/formResponses/${uid}`).push().key;
    values[`${key}`] = transaction;
  });

  const result = await db.ref(`/formResponses/${uid}`).update(values);
  return result;
};
