import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
    :root {
        font-size: 16px;
        overflow: scroll;
    }

    body, div, button {
        font-family: 'Roboto', sans-serif;
    }
`;
