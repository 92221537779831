import { isWithinInterval, parseISO } from "date-fns";
import { useState } from "react";
import styled from "styled-components";
import { FormResponseItem } from "../../services/firebase";
import { Table } from "../table/Table";

const CategoryDivider = styled.div<{ color: string }>`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 800;
  grid-column: 1/-1;
  background-color: ${({ color }) => color};
  padding: 10px 8px;
  margin-top: -1px;
  cursor: pointer;
`;

type TableCategorySectionProps = {
  formResponseItems: FormResponseItem[];
  color: string;
  selectedMonth: any;
  categoryLabel: string;
  categoryValue: number;
  uid: string;
  categories: string[];
  actionSection?: React.ReactElement
};

export const TableCategorySection: React.FC<TableCategorySectionProps> = ({
  formResponseItems,
  color,
  selectedMonth,
  categoryLabel,
  categoryValue,
  uid,
  categories,
  actionSection,
}) => {
  const [itemsOpen, setItemsOpen] = useState(true);

  return (
    <>
      <CategoryDivider onClick={() => setItemsOpen(!itemsOpen)} color={color}>
        <span>
          {categoryLabel} | {categoryValue.toFixed(2)}
        </span>
        {actionSection}
      </CategoryDivider>

      {formResponseItems?.map((props) => {
        return (
          isWithinInterval(parseISO(props.timestamp), selectedMonth) && (
            <Table
              show={itemsOpen}
              uid={uid}
              {...props}
              categories={categories}
            />
          )
        );
      })}
    </>
  );
};
