import { useState, FocusEvent } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { updateCell, UpdateCellType } from "../../services/firebase";
import { Cell, CellFieldStyles, CellProps, StyledCellInputProps } from "./styles";

const StyledCellInput = styled.input`
    ${CellFieldStyles}
`;

type CellInputProps = CellProps &
    StyledCellInputProps & {
        rowId: string;
        value: string | number;
        uid: string;
        columnName: string;
        formatValue?: (value: string | number) => string;
    };

export const CellInput: React.FC<CellInputProps> = ({
    rowId,
    uid,
    columnName,
    value,
    formatValue,
    first,
    textAlign,
}) => {
    const [cellValue, setCellValue] = useState(value);
    const [displayValue, setDisplayValue] = useState(formatValue ? formatValue(value) : value);

    const { isLoading, mutate } = useMutation<any, any, UpdateCellType, any>(({ uid, rowId, columnName, value }) =>
        updateCell({ uid, rowId, columnName, value })
    );

    const onValueChange = (event: FocusEvent<HTMLInputElement>) => {
        setDisplayValue(event.target.value);
        // formatValue && setDisplayValue(formatValue(event.target.value));
    };

    const onFocus = () => {
        if (formatValue) {
            setDisplayValue(cellValue);
        }
    };

    const onBlur = () => {
        if (cellValue !== displayValue) {
            mutate({ uid, rowId, columnName, value: displayValue });
            setCellValue(displayValue);
        }
        setDisplayValue(formatValue ? formatValue(displayValue) : displayValue);
    };

    return (
        <Cell isLoading={isLoading} textAlign={textAlign}>
            <StyledCellInput
                onFocus={onFocus}
                onChange={onValueChange}
                onBlur={onBlur}
                value={displayValue}
                first={first}
            />
        </Cell>
    );
};
