import { Plus, X } from "phosphor-react";
import { useState } from "react";
import { animated, useSpring } from "react-spring";
import styled, { css } from "styled-components";
import { AddTransaction, AddTransactionProps } from "./AddTransaction";

const StyledX = styled(X)`
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 3px;
  border-radius: 50px;
  border: 2px solid black;
  transition: transform 0.2s ease-in-out;
  background-color: #ffaeae;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const Title = styled.h2`
  margin: 0;
`;

const FAB = styled(animated.div)<{ isOpen?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0px 16px;
  transition: transform 0.2s ease-in-out;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      &:hover {
        cursor: pointer;
        background-color: #ff9a9a;
        transform: scale(1.05);
      }
    `}
`;

const springStyles = [
  {
    width: 50,
    height: 50,
    bottom: "0px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    backgroundColor: "#ffaeae",
  },
  {
    width: 300,
    height: 400,
    bottom: "0px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "16px",
    backgroundColor: "#ffd1d3",
  },
  {
    width: 50,
    height: 50,
    bottom: "-81px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    backgroundColor: "#ffaeae",
  },
];

export const AddTransactionFAB: React.FC<
  AddTransactionProps & { showFAB: boolean }
> = ({ showFAB, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentStyleIndex = showFAB ? +isOpen : isOpen ? 1 : 2;
  const styles = useSpring(springStyles[currentStyleIndex]);

  return (
    <FAB
      style={styles}
      onClick={() => !isOpen && setIsOpen(true)}
      isOpen={isOpen}
    >
      {isOpen ? (
        <>
          <StyledX size={24} onClick={() => setIsOpen(false)} />
          <Title>Add Transaction</Title>
          <AddTransaction {...props} />{" "}
        </>
      ) : (
        <Plus size={32} />
      )}
    </FAB>
  );
};
