const size = {
    small: 576,
    medium: 768,
    large: 992,
};

const device = {
    small: `(min-width: ${size.small}px)`,
    medium: `(min-width: ${size.medium}px)`,
    large: `(min-width: ${size.large}px)`,
};

export type ThemeType = {
    device: typeof device;
};

export const theme: ThemeType = {
    device: device,
};
