import { isBefore, isWithinInterval } from "date-fns";
import { MonthType } from "../App";
import { CategoriesType, FormResponseItem, FormResponseType } from "../services/firebase";

export type ResponseType = "income" | "savings" | "fixedExpenses" | "variableExpenses";
export const reduceFormResponses = (fr: any, cat: CategoriesType) =>
    Object.keys(fr).reduce<FormResponseType>(
        (acc, currKey) => {
            const currValue = { rowId: currKey, ...fr[currKey] } as FormResponseItem;
            const { category } = currValue;
            if (cat.income.includes(category)) {
                acc.incomeItems.push(currValue);
            } else if (cat.savings.includes(category)) {
                acc.savingsItems.push(currValue);
            } else if (cat.fixedExpenses.includes(category)) {
                acc.fixedExpensesItems.push(currValue);
            } else if (cat.variableExpenses.includes(category)) {
                acc.variableExpensesItems.push(currValue);
            } else acc.leftover.push(currValue);

            return acc;
        },
        {
            incomeItems: [],
            savingsItems: [],
            fixedExpensesItems: [],
            variableExpensesItems: [],
            leftover: [],
        }
    );

export type FormResponseTotals = {
    value: number;
    previousValues: number;
};

export const getFormResponseTotals = (selectedMonth: MonthType, items?: FormResponseItem[]): FormResponseTotals => {
    if (!items) return { value: 0, previousValues: 0 };
    return items.reduce(
        (acc, curr) => {
            const parsedTimestamp = Date.parse(curr.timestamp);
            if (
                isWithinInterval(parsedTimestamp, {
                    start: selectedMonth.start,
                    end: selectedMonth.end,
                })
            ) {
                acc.value += Number(curr.amount);
            } else if (isBefore(parsedTimestamp, selectedMonth.start)) acc.previousValues += Number(curr.amount);

            return acc;
        },
        { value: 0, previousValues: 0 }
    );
};
