import firebase from "firebase";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
const config = {
    apiKey: "AIzaSyDyn88MOUiRT-E-2c15EltK3Yl9o9Zde30",
    authDomain: "budget-c4c06.firebaseapp.com",
    databaseURL: "https://budget-c4c06.firebaseio.com",
    projectId: "budget-c4c06",
    storageBucket: "budget-c4c06.appspot.com",
    messagingSenderId: "505040572289",
    appId: "1:505040572289:web:1064682a3198cd58bca280",
    measurementId: "G-Z9PSRTLSMB",
};

firebase.initializeApp(config);
const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
