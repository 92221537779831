import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import firebase from "firebase";
import { useMutation, useQuery } from "react-query";
import {
  getMonth,
  getYear,
  subMonths,
  addMonths,
  isWithinInterval,
  parseISO,
  formatISO,
} from "date-fns";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import {
  addTransactions,
  getFormResponses,
  getUserDetails,
  Transaction,
} from "./services/firebase";
import { Auth } from "./components";
import { getFormResponseTotals } from "./utils/formResponseUtils";
import { GlobalStyle } from "./globalStyles";
import { List } from "./pages/List";

const Layout = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

export type MonthType = {
  start: Date;
  end: Date;
};

const defaultMonthStartDate = 1;

const getInitialSelectedMonth = (monthStartDate: number): MonthType => {
  const today = new Date();
  const currentDateNumber = today.getDate();
  const baseDate = new Date(getYear(today), getMonth(today), monthStartDate);
  return currentDateNumber >= monthStartDate
    ? {
        start: baseDate,
        end: addMonths(baseDate, 1),
      }
    : {
        start: subMonths(baseDate, 1),
        end: baseDate,
      };
};

const App = () => {
  const [user, setUser] = useState<firebase.User | undefined>(undefined);
  const [selectedMonth, setSelectedMonth] = useState(
    getInitialSelectedMonth(defaultMonthStartDate)
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      user && setUser(user);
    });
  }, []);

  const categoriesQuery = useQuery(
    "categories",
    () => user && getUserDetails(user.uid),
    {
      enabled: !!user,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (
          data?.monthStartDate &&
          data?.monthStartDate !== defaultMonthStartDate
        ) {
          setSelectedMonth(getInitialSelectedMonth(data.monthStartDate));
        }
      },
    }
  );

  const formResponsesQuery = useQuery(
    "formResponses",
    () =>
      user &&
      categoriesQuery.data &&
      getFormResponses(user.uid, categoriesQuery.data.categories),
    {
      enabled: !!user && !!categoriesQuery.data,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const pushTransactionsMutation = useMutation<
    any,
    any,
    { data: Transaction[]; uid: string },
    any
  >(({ data, uid }) => addTransactions({ uid, transactions: data }), {
    onSuccess: formResponsesQuery.refetch,
  });

  const getPreviousMonth = () => {
    setSelectedMonth({
      start: subMonths(selectedMonth.start, 1),
      end: subMonths(selectedMonth.end, 1),
    });
  };

  const getNextMonth = () =>
    setSelectedMonth({
      start: addMonths(selectedMonth.start, 1),
      end: addMonths(selectedMonth.end, 1),
    });

  const copyLastMonthsExpenses = () => {
    const previousMonth = {
      start: subMonths(selectedMonth.start, 1),
      end: subMonths(selectedMonth.end, 1),
    };

    const lastMonthFixedExpenses =
      formResponsesQuery.data?.fixedExpensesItems.filter((x) =>
        isWithinInterval(parseISO(x.timestamp), previousMonth)
      );

    const newFixedExpenses = lastMonthFixedExpenses?.map(
      (x) =>
        ({
          category: x.category,
          item: x.item,
          amount: x.amount.toString(),
          timestamp: formatISO(addMonths(parseISO(x.timestamp), 1)),
        } as Transaction)
    );

    newFixedExpenses &&
      user &&
      pushTransactionsMutation.mutate({
        uid: user.uid,
        data: newFixedExpenses,
      });
  };

  if (categoriesQuery.isLoading || formResponsesQuery.isLoading)
    return <h1>Loading...</h1>;

  const incomeTotals = getFormResponseTotals(
    selectedMonth,
    formResponsesQuery.data?.incomeItems
  );
  const savingsTotals = getFormResponseTotals(
    selectedMonth,
    formResponsesQuery.data?.savingsItems
  );
  const variableExpensesTotals = getFormResponseTotals(
    selectedMonth,
    formResponsesQuery.data?.variableExpensesItems
  );
  const fixedExpensesTotals = getFormResponseTotals(
    selectedMonth,
    formResponsesQuery.data?.fixedExpensesItems
  );
  const leftoverTotals =
    incomeTotals.value -
    savingsTotals.value -
    variableExpensesTotals.value -
    fixedExpensesTotals.value;
  const previousLeftovers =
    incomeTotals.previousValues -
    savingsTotals.previousValues -
    variableExpensesTotals.previousValues -
    fixedExpensesTotals.previousValues;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        {user ? (
          <>
            {formResponsesQuery.isLoading ? (
              <h1>Loading...</h1>
            ) : (
              <>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <List
                          user={user}
                          categories={categoriesQuery.data?.categories}
                          selectedMonth={selectedMonth}
                          formResponses={formResponsesQuery.data}
                          incomeTotals={incomeTotals}
                          savingsTotals={savingsTotals}
                          fixedExpensesTotals={fixedExpensesTotals}
                          variableExpensesTotals={variableExpensesTotals}
                          previousLeftovers={previousLeftovers}
                          currentLeftovers={previousLeftovers + leftoverTotals}
                          getPreviousMonth={getPreviousMonth}
                          getNextMonth={getNextMonth}
                          refetchFormResponses={formResponsesQuery.refetch}
                          copyLastMonthsExpenses={copyLastMonthsExpenses}
                        />
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </>
            )}
          </>
        ) : (
          <Auth />
        )}
      </Layout>
    </ThemeProvider>
  );
};

export default App;
