import {
  addTransaction,
  CategoriesType,
  Transaction,
} from "../../services/firebase";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { formatISO } from "date-fns";
import { ResponseType } from "../../utils/formResponseUtils";
import styled from "styled-components";

export type AddTransactionProps = {
  uid: string;
  categories: CategoriesType;
  onSuccess: () => void;
};

type Inputs = Omit<Transaction, "timestamp"> & { responseType: ResponseType };

const StyledForm = styled.form`
  padding: 12px 0;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 800;
  font-size: 14px;
`;

const StyledInput = styled.input`
  height: 42px;
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
  background-color: #f7f0f0;
  border: none;
  font-family: "Roboto";
`;

const StyledSelect = styled.select`
  height: 42px;
  width: 100%;
  padding: 0;
  background-color: #f7f0f0;
  border: none;
  font-family: "Roboto";
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  display: block;
`;

export const AddTransaction: React.FC<AddTransactionProps> = ({
  uid,
  categories,
  onSuccess,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { responseType: "variableExpenses" },
  });
  const responseTypeRef = useRef<HTMLSelectElement | null>(null);
  const { ref, ...responseTypeProps } = register("responseType", {
    required: { value: true, message: "This field is required" },
  });
  const responseType = watch("responseType");
  const responseTypes = Object.keys(categories);

  useEffect(() => {
    setValue("category", categories[responseType][0], { shouldValidate: true });
  }, [setValue, categories, responseType]);

  const { mutate } = useMutation<any, any, Transaction, any>(
    (data) => addTransaction({ uid, transaction: data }),
    {
      onSuccess: () => {
        onSuccess();
        reset();
      },
    }
  );

  const onSubmit: SubmitHandler<Inputs> = ({ responseType, ...data }) => {
    mutate({ ...data, timestamp: formatISO(new Date()) });
    responseTypeRef?.current?.focus();
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <StyledLabel>Response Types</StyledLabel>
        <StyledSelect
          {...responseTypeProps}
          name="responseType"
          ref={(e) => {
            ref(e);
            responseTypeRef.current = e;
          }}
        >
          {responseTypes.map((responseType) => {
            const displayResponseType = responseType.replace(/([A-Z])/g, " $1");
            return (
              <option key={responseType} value={responseType}>
                {displayResponseType.charAt(0).toUpperCase() +
                  displayResponseType.slice(1)}
              </option>
            );
          })}
        </StyledSelect>
        {errors.responseType && (
          <ErrorMessage>{errors.responseType.message}</ErrorMessage>
        )}
      </InputWrapper>
      <InputWrapper>
        <StyledLabel>Category</StyledLabel>
        {categories[responseType] && (
          <StyledSelect
            {...register("category", {
              required: { value: true, message: "This field is required" },
            })}
          >
            {categories[responseType].map((category) => {
              return (
                <option key={category} value={category}>
                  {category}
                </option>
              );
            })}
          </StyledSelect>
        )}
        {errors.category && (
          <ErrorMessage>{errors.category.message}</ErrorMessage>
        )}
      </InputWrapper>
      <InputWrapper>
        <StyledLabel>Item</StyledLabel>
        <StyledInput
          {...register("item", {
            required: { value: true, message: "This field is required" },
          })}
        />
        {errors.item && <ErrorMessage>{errors.item.message}</ErrorMessage>}
      </InputWrapper>
      <InputWrapper>
        <StyledLabel>Amount</StyledLabel>
        <StyledInput
          {...register("amount", {
            required: { value: true, message: "This field is required" },
          })}
          type="number"
          step=".01"
        />
        {errors.amount && <ErrorMessage>{errors.amount.message}</ErrorMessage>}
      </InputWrapper>
      <button type="submit">Submit</button>
    </StyledForm>
  );
};
