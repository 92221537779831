import { useState, FocusEvent } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { updateCell, UpdateCellType } from "../../services/firebase";
import { Cell, CellFieldStyles, CellProps, StyledCellInputProps } from "./styles";

const StyledCellInput = styled.select`
    ${CellFieldStyles}
`;

type CellInputProps = CellProps &
    StyledCellInputProps & {
        rowId: string;
        value: string | number;
        values: string[];
        uid: string;
        columnName: string;
    };

export const CellSelect: React.FC<CellInputProps> = ({
    rowId,
    uid,
    columnName,
    value,
    values,
    first,
    textAlign,
}) => {
    const [cellValue, setCellValue] = useState(value);

    const { isLoading, mutate } = useMutation<any, any, UpdateCellType, any>(({ uid, rowId, columnName, value }) =>
        updateCell({ uid, rowId, columnName, value })
    );

    const onValueChange = (event: FocusEvent<HTMLSelectElement>) => {
        mutate({ uid, rowId, columnName, value: event.target.value });
        setCellValue(event.target.value);
    };

    return (
        <Cell isLoading={isLoading} textAlign={textAlign}>
            <StyledCellInput
                onChange={onValueChange}
                value={cellValue}
                first={first}
            >
                {values.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </StyledCellInput>
        </Cell>
    );
};
