import { CaretLeft, CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { useScrollDirection } from "../../utils/useScrollDirection";
import {
  AddTransactionFAB,
  AddTransactionProps,
} from "../addTransaction";

const FabContainer = styled.div`
  position: fixed;
  align-items: flex-end;
  gap: 8px;
  right: 30px;
  bottom: 30px;
  display: flex;
`;

const FAB = styled(animated.div)`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0px 16px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const springStyles = [
  {
    bottom: "-81px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    backgroundColor: "#ffaeae",
  },
  {
    bottom: "0px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    backgroundColor: "#ffaeae",
  },
];

export const NavigationFAB: React.FC<
  AddTransactionProps & {
    onLeftClick: () => void;
    onRightClick: () => void;
  }
> = ({ onLeftClick, onRightClick, ...addTransactionData }) => {
  const [showFAB, setShowFAB] = useState(true);
  const styles = useSpring(springStyles[+showFAB]);
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    if (scrollDirection === "up") setShowFAB(true);
    else if (scrollDirection === "down") setShowFAB(false);
  }, [scrollDirection]); // we do not want to run on updates to fabState

  return ReactDOM.createPortal(
    <FabContainer>
      <FAB style={styles} onClick={onLeftClick}>
        <CaretLeft size={32} />
      </FAB>
      <FAB style={styles} onClick={onRightClick}>
        <CaretRight size={32} />
      </FAB>
      <AddTransactionFAB showFAB={showFAB} {...addTransactionData} />
    </FabContainer>,
    document.getElementById("modal-root") as HTMLElement
  );
};
