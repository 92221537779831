import styled, { css } from "styled-components";

export type CellProps = { textAlign?: "left" | "center" | "right"; isLoading?: boolean };

export const CellStyles = css<CellProps>`
    background-color: ${({ isLoading }) => (isLoading ? "#CDF2CA" : "#fff")};
    border: none;
    ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: background-color 0.5s ease;
`;

export const Cell = styled.div`
    ${CellStyles}
`;

export type StyledCellInputProps = { first?: true | false };

export const CellFieldStyles = css<StyledCellInputProps>`
    padding: 10px 8px;
    ${({ first }) => !first && "margin-left: 8px;"};
    border: none;
    background: none;
    font-size: 18px;
    width: 100%;
    text-align: inherit;
`;
